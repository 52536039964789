import React, { useState } from "react";
import Handimg from "../assets/images/hand.webp";
import axios from 'axios';

const Enquiry = () => {
  const [enquirydata, setenquirydata] = useState({
    Name: "",
    Organisation: "",
    Email: "",
    Website: "",
    Number: "",
    Requirement: "",
    Message: "",
    Fabrication: "",
    Designing: "",
  });
  const [recipientEmail, setRecipientEmail] = useState('');

  const onchangedata = (e) => {
    const { name, value } = e.target;
    setenquirydata((values) => ({ ...values, [name]: value }));
  };

  
  const onRecipientEmailChange = (e) => {
    setRecipientEmail(e.target.value);
  };

  const submitbtn = () => {
    const formData = {
      to: recipientEmail,
      subject: 'Enquiry Form Submission',
      text: JSON.stringify(enquirydata)
    };

    axios.post('http://localhost:5000/send-email', formData)
      .then(response => {
        console.log(response.data.message);
      })
      .catch(error => {
        console.error('Error sending email:', error);
      });
  };
  return (
    <div className="startdiv bg-[#f3f3f3]">
      <div className="mt-5">
        <div className="grid grid-cols-2 gap-4 mt-5 p-5 bg-white">
          <div class="col-span-1 md:p-5">
            <h1 className="text-[30px] font-bold">
              Want to make a deal with us ?
            </h1>
            <p className="text-[18px] leading-8 text-justify text-[#5C5C5C] font-normal">
              It's a very good idea to start a deal with us. Because we are
              flexible persons who can work for your customized needs. We are
              one of the leading PCB manufacturing industry in Coimbatore.
              Having 20 years of experience in the work field.{" "}
            </p>
            <h2 className="text-[22px] font-bold mt-16">
              "Try not to become a man of success. Rather become a man of
              value."
            </h2>
            <img src={Handimg} alt="" />
          </div>
          <div class="col-span-1 bg-[#aaa8a891] pl-5 md:pl-14 pt-5 pb-7">
            <h1 className="text-[30px] font-semobold ">Enquiry Form</h1>
            <input
              type="text"
              className="border-2 p-2 w-11/12 bg-transparent border-gray-500 mt-3"
              placeholder="Your Name"
              name="Name"
              onChange={onchangedata}
            />
            {/* <input
              type="text"
              className="border-2 p-2 w-11/12 bg-transparent border-gray-500 mt-3"
              placeholder="Recipient's Email"
              value={recipientEmail}
              onChange={onRecipientEmailChange}
            /> */}
            <input
              type="text"
              className="border-2 p-2 w-11/12 bg-transparent border-gray-500 mt-3"
              placeholder="Your Organisation"
              name="Organisation"
              onChange={onchangedata}
            />
            <input
              type="text"
              className="border-2 p-2 w-11/12 bg-transparent border-gray-500 mt-3"
              placeholder="Your Email"
              name="Email"
              onChange={onchangedata}
            />
            <input
              type="text"
              className="border-2 p-2 w-11/12 bg-transparent border-gray-500 mt-3"
              placeholder="Your Website"
              name="Website"
              onChange={onchangedata}
            />
            <input
              type="text"
              className="border-2 p-2 w-11/12 bg-transparent border-gray-500 mt-3"
              placeholder="Your Contact Number"
              name="Number"
              onChange={onchangedata}
            />

            <select
              className="border-2 p-2 w-11/12 bg-transparent border-gray-500 mt-3"
              name="Requirement"
              onChange={onchangedata}
            >
              <option value="">Requirement</option>
              <option value="PCB Fabrication">PCB Fabrication</option>
              <option value="PCB Designing">PCB Designing</option>
              <option value="PCB Assembling">PCB Assembling</option>
            </select>
            <div className="grid grid-cols-2 gap-4 mt-2 p-5">
              <div class="col-span-1">
                <h1>If Fabrication </h1>
                <input
                  type="radio"
                  id="Single Sided"
                  name="Fabrication"
                  value="Single Sided"
                  className="mt-5"
                  onChange={onchangedata}
                />
                <label for="Single Sided" className="ml-2">
                  Single Sided
                </label>
                <br />

                <input
                  type="radio"
                  id="Multi Layer"
                  name="Fabrication"
                  value="Multi Layer"
                  className="mt-2"
                  onChange={onchangedata}
                />
                <label for="Multi Layer" className="ml-2">
                  Multi Layer
                </label>
              </div>
              <div class="col-span-1">
                <h1>If Designing</h1>
                <input
                  type="radio"
                  id="Double Layer"
                  name="Designing"
                  value="Double Layer"
                  className="mt-5"
                  onChange={onchangedata}
                />
                <label for="Double Layer" className="ml-2">
                  Double Layer
                </label>
                <br />
                <input
                  type="radio"
                  id="Single Layer"
                  name="Designing"
                  value="Single Layer"
                  className="mt-2"
                  onChange={onchangedata}
                />
                <label for="Single Layer" className="ml-2">
                  Single Layer
                </label>
                <br />
                <input
                  type="radio"
                  id="Multilayer"
                  name="Designing"
                  value="Multilayer"
                  className="mt-2"
                  onChange={onchangedata}
                />
                <label for="Multilayer" className="ml-2">
                  Multilayer
                </label>
                <br />
              </div>
            </div>
            <textarea
              className="border-2 p-2 w-11/12 bg-transparent border-gray-500 mt-3"
              rows="4"
              placeholder="Message to us"
              name="Message"
              onChange={onchangedata}
            ></textarea>

            <button className="submitbtn mt-4" onClick={submitbtn}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
