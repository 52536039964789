/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";
import Slider1 from "../assets/images/slider1.webp";
import Slider2 from "../assets/images/slider2.webp";
import Slider3 from "../assets/images/slider3.webp";
import Slider4 from "../assets/images/slider4.webp";
import Slider5 from "../assets/images/slider5.webp";
import Slider6 from "../assets/images/slider6.webp";
import Qualityimg from "../assets/images/quality.webp";
import axios from "axios";
import Internship from "./Internship";

const Home = () => {
  const pagination = {
    clickable: true,
  };
  const [recipientEmail, setRecipientEmail] = useState("");

  const [applydata, setapplydata] = useState({
    Name: "",
    Email: "",
    Message: "",
    Number: "",
  });
  const onchangedata = (e) => {
    const { name, value } = e.target;
    setapplydata((values) => ({ ...values, [name]: value }));
  };

  const submitbtn = () => {
    const formData = {
      to: recipientEmail,
      subject: "Get in touch with us _",
      text: JSON.stringify(applydata),
    };

    axios
      .post("http://localhost:5000/send-email", formData)
      .then((response) => {
        console.log(response.data.message);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  return (
    <div className="startdiv bg-[#f3f3f3]">
      <Swiper
        // pagination={true}
        pagination={pagination}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
        // Autoplay={true}
        // loop={true}
        // autoplay={{
        //   delay: 3000,
        //   disableOnInteraction: false,
        // }}
      >
        <SwiperSlide>
          <div class="container">
            <img
              src={Slider2}
              alt="Cover1"
              className="w-full md:h-[27rem] h-[15rem] md:object-fill object-contain opacity-90"
            />
            <div class="centered">
              <h1 className="md:text-[40px] text-[18px] font-bold">WE ARE</h1>
              <br />
              <h1 className="md:text-[40px] text-[18px] font-bold">
                MASTER OF YOUR DESTINY
              </h1>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="container">
            <img
              src={Slider1}
              alt="Cover1"
              className="w-full md:h-[27rem] h-[15rem] md:object-fill object-contain opacity-90"
            />
            <div class="centered">
              {" "}
              <h1 className="md:text-[40px] text-[18px] font-bold">
                {" "}
                WE ARE HERE TO
              </h1>
              <br />
              <h1 className="md:text-[40px] text-[18px] font-bold">
                SEED YOUR INNOVATIONS
              </h1>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="container">
            <img
              src={Slider3}
              alt="Cover1"
              className="w-full md:h-[27rem] h-[15rem] md:object-fill object-contain opacity-90"
            />
            <div class="centered">
              {" "}
              <h1 className="md:text-[40px] text-[18px] font-bold">
                WE - THE BEST WAY
              </h1>
              <br />
              <h1 className="md:text-[40px] text-[18px] font-bold">
                MAKE YOUR DREAMS COME TRUE
              </h1>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="container">
            <img
              src={Slider4}
              alt="Cover1"
              className="w-full md:h-[27rem] h-[15rem] md:object-fill object-contain opacity-90"
            />
            <div class="centered">
              {" "}
              <h1 className="md:text-[40px] text-[18px] font-bold">
                SPEND AN HOUR WITH US
              </h1>
              <br />
              <h1 className="md:text-[40px] text-[18px] font-bold">
                YOU CAN ACHIEVE YOUR GOALS
              </h1>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="container">
            <img
              src={Slider5}
              alt="Cover1"
              className="w-full md:h-[27rem] h-[15rem] md:object-fill object-contain opacity-90"
            />
            <div class="centered">
              {" "}
              <h1 className="md:text-[40px] text-[18px] font-bold">
                YOUR LIMITATION
              </h1>
              <br />
              <h1 className="md:text-[40px] text-[18px] font-bold">
                IT'S ONLY
              </h1>
              <br />
              <h1 className="md:text-[40px] text-[18px] font-bold">
                YOUR IMAGINATION
              </h1>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="container">
            <img
              src={Slider6}
              alt="Cover1"
              className="w-full md:h-[27rem] h-[15rem] md:object-fill object-contain opacity-90"
            />
            <div class="centered">
              {" "}
              <h1 className="md:text-[40px] text-[18px] font-bold">
                COME TO US
              </h1>
              <br />
              <h1 className="md:text-[40px] text-[18px] font-bold">
                GO TO BED WITH SATISFACTION
              </h1>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="mt-2">
        <div className="grid grid-cols-2 gap-4 mt-5">
          <div class="col-span-1 md:p-5 md:border-r-4 md:border-slate-500">
            <h1 className="text-[30px] font-bold">MISSION :</h1>
            <p className="text-[18px] leading-8 text-justify text-[#5C5C5C] font-normal">
              We are committed to maintaining customer satisfaction, trust, and
              integrity by delivering quality products and services in
              accordance with industry compliances, best practices and
              continuous process improvement.
            </p>
          </div>
          <div class="col-span-1 md:p-5">
            <h1 className="text-[30px] font-bold">VISSION :</h1>
            <p className="text-[18px] leading-8 text-justify text-[#5C5C5C] font-normal">
              To be a Global Leader in facilitating innovative and Value-added
              services to every individual and corporate associated with DSR
              Systems
            </p>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="grid grid-cols-3 gap-4">
          <div class="col-span-2 md:p-5">
            <h1 className="text-[30px] font-bold">QUALITY :</h1>
            <p className="text-[18px] leading-8 text-justify text-[#5C5C5C] font-normal">
              A quality performance is one of the keystones of our company
              culture, and it is considered a personal responsibility of all
              employees. To maintain the quality performance of all business
              units at the highest level, the following guidelines as defined in
              our policy:​​
            </p>
            <div className="homeullist">
              <ul className="ml-7 mt-5">
                <li>
                  To full fill or exceed Customer needs and expectations by
                  delivering quality products well within timelines.
                </li>
                <li>
                  To develop and maintain the commitment to continual
                  improvement and communicate our goals and objectives to every
                  employee
                </li>
                <li>
                  To encourage a working environment where Learning and Sharing
                  are the tools to generate improved productivity and align one
                  and all to the company Vision and Mission.
                </li>
                <li>
                  To furnish a system of policies which are periodically
                  reviewed and updated, to ensure competent performance
                  throughout all the teams at DSR SYSTEMS
                </li>
              </ul>
            </div>
          </div>
          <div className="col-span-1 p-5 mt-5">
            <img
              src={Qualityimg}
              alt=""
              className="w-80 h-80 object-contain rounded-2xl"
            />
          </div>
        </div>
        <h3 className="text-center text-[#5C5C5C] text-[22px] font-bold">
          "Our vision, mission, and goal are to satisfy our customers by giving
          on-time delivery with expected quality and the cost will be
          affordable."
        </h3>
      </div>
      <div className="mt-5">
        <h1 className="text-[30px] font-bold">UNIQUENESS :</h1>
        <p className="text-[18px] leading-8 text-justify text-[#5C5C5C] font-normal mt-2">
          <span className="text-[20px] font-bold text-black">DSR SYSTEMS</span>{" "}
          uniqueness stands on the concept of PCB ASSEMBLY – We do hole and SMD
          soldering with our equipped manufacturing system and well qualified
          and experienced staff members. 1206, 0805, 0603 and 0402, the level of
          components will be soldered as per your circuit diagram. Different
          types of packages like TSSOP, QFP, TQFP, SOT, TSOP, SOIC, QFN and
          other kinds with different types of modules like SIM 300 with 60 pin
          B2B Connector, SIM900 will be soldered with expected quality.
        </p>
      </div>
      <div className="mt-5">
        <div className="grid grid-cols-3 gap-4 mt-5">
          <div class="col-span-2">
            <div className="flex">
              <svg
                preserveAspectRatio="xMidYMid meet"
                data-bbox="68.869 393.1 1062.162 413.9"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="68.869 393.1 1062.162 413.9"
                role="presentation"
                aria-hidden="true"
                className="h-10 w-10 mr-5"
              >
                <g>
                  <path d="M1118.4 487c-7.4-.5-13.9 5.1-14.4 12.6-5.2 75-38 133.9-74.9 135.1 1.9-11.3 2.9-22.8 2.9-34.6 0-114.2-92.9-207-207-207-109.6 0-199.6 85.6-206.6 193.5h-23.8c-7-107.9-97-193.5-206.6-193.5-114.1 0-207 92.9-207 207 0 11.2.9 22.1 2.6 32.8-.8.1-1.6.3-2.4.6-1.2.4-5.1 1.3-9.2 1.3-37.4 0-70.8-59.4-76.1-135.1-.5-7.5-7-13.1-14.4-12.6-7.5.5-13.1 7-12.6 14.4 6.5 92.9 49.8 160.3 103 160.3 7.2 0 13.9-1.4 17.6-2.6 25.7 85.4 105 147.8 198.6 147.8 109.6 0 199.6-85.6 206.6-193.5h23.8c7 107.9 97 193.5 206.6 193.5 92.7 0 171.4-61.3 197.7-145.5 1.7.1 3.4.2 5.2.2 53.2 0 96.6-67.4 103-160.3.5-7.4-5.1-13.9-12.6-14.4zM388.1 780c-99.2 0-180-80.7-180-180 0-99.2 80.7-180 180-180s180 80.7 180 180c-.1 99.2-80.8 180-180 180zm436.9 0c-99.2 0-180-80.7-180-180 0-99.2 80.7-180 180-180 99.2 0 180 80.7 180 180 0 99.2-80.7 180-180 180z"></path>
                  <path d="M435.2 601h-85c-7.5 0-13.5 6-13.5 13.5s6 13.5 13.5 13.5h85c7.5 0 13.5-6 13.5-13.5s-6-13.5-13.5-13.5z"></path>
                  <path d="M871.2 601h-84c-7.5 0-13.5 6-13.5 13.5s6 13.5 13.5 13.5h84c7.5 0 13.5-6 13.5-13.5s-6-13.5-13.5-13.5z"></path>
                </g>
              </svg>
              <h1 className="text-[25px] font-bold">A Bit About Us _</h1>
            </div>
            <p className="text-[18px] leading-8 text-justify text-[#5C5C5C] font-normal mt-2">
              <span className="text-[20px] font-bold text-black">
                DSR SYSTEMS
              </span>{" "}
              - PCB solution can handle everything from design your PCB,
              fabrication, assembly, and testing. We have been fabricating PCBs
              for over eight years and are fully compliant as per ISO9001:2015
              quality standards. All of our standard printed circuit boards are
              IPC2 compliant, and we do strict internal quality assurance
              testing to ensure the board.
            </p>
          </div>
          <div class="col-span-1">
            <div className="flex">
              <svg
                preserveAspectRatio="xMidYMid meet"
                data-bbox="7.9 34.8 99.6 52.4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="7.9 34.8 99.6 52.4"
                role="presentation"
                aria-hidden="true"
                className="h-10 w-10 mr-5"
              >
                <g>
                  <path d="M106.3 66.4H95.4v-3.7H99c.6 0 1.1-.6 1.1-1.3s-.5-1.3-1.1-1.3h-3.6v-3.2h9.6c.6 0 1.1-.6 1.1-1.3s-.5-1.3-1.1-1.3h-9.6V43.9c0-3.1-1.8-8.8-8.5-9.1h-70c-1.3 0-2.9.4-4.5 1.2l-1.6.7.3.2c-1.8 1.4-3.2 3.6-3.2 7.1v34c0 3.2 1.9 9.2 9.2 9.2h69.2c3.2 0 9.2-1.9 9.2-9.2v-9.2h10.9c.6 0 1.1-.6 1.1-1.3s-.6-1.1-1.2-1.1zM17.1 37.3h69.7L51.6 59.5 16.5 37.3h.6zm75.8 40.5c0 1.6-.4 2.8-.9 3.7L65.3 70.7c-.6-.3-1.4 0-1.6.7-.3.6 0 1.4.7 1.6l25.7 10.4c-1.7 1.1-3.5 1.2-3.9 1.2H17.1c-1.7 0-2.9-.4-3.8-1l25.9-10.5c.6-.3.9-1 .7-1.6-.3-.6-1-.9-1.6-.7L11.4 81.6c-.9-1.6-1-3.3-1-3.7v-34c0-3.1 1.4-4.8 2.9-5.7l38.4 24.2L90 38.1c2.8 1.7 2.9 5.2 2.9 5.7v10.6h-8.2c-.6 0-1.1.6-1.1 1.3s.5 1.3 1.1 1.3h8.2v3.2h-2.4c-.6 0-1.1.6-1.1 1.3s.5 1.3 1.1 1.3h2.4v3.7h-3.3c-.6 0-1.1.6-1.1 1.3s.5 1.3 1.1 1.3h3.3v8.7z"></path>
                </g>
              </svg>
              <h1 className="text-[25px] font-bold">Get in touch with us _</h1>
            </div>
            <div className="p-5">
              <input
                type="text"
                className="border p-1 w-full bg-transparent border-current mt-3"
                placeholder="Name"
                name="Name"
                onChange={onchangedata}
              />
              <input
                type="text"
                className="border p-1 w-full bg-transparent border-current mt-3"
                placeholder="Email"
                name="Email"
                onChange={onchangedata}
              />
              <input
                type="text"
                className="border p-1 w-full bg-transparent border-current mt-3"
                placeholder="Phone"
                name="Number"
                onChange={onchangedata}
              />
              <textarea
                className="border p-1 w-full bg-transparent border-current mt-3"
                placeholder="Message"
                name="Message"
                onChange={onchangedata}
              ></textarea>
              <button
                className="bg-[#96d16f] enquirybtn mt-4 float-right"
                onClick={submitbtn}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <Internship />
    </div>
  );
};

export default Home;
