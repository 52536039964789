/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import Logo from "../assets/images/logo.jpg";
import { AiOutlineMail } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { BiPhoneCall } from "react-icons/bi";
import { CiUser } from "react-icons/ci";
import { FaFacebook } from "react-icons/fa";
import { TiSocialLinkedin } from "react-icons/ti";
import { AiOutlineTwitter } from "react-icons/ai";

const Footer = () => {
  return (
    <div className="bg-black px-5 md:px-16 text-white py-10 ">
      <div className=" grid row-span-3 items-center md:items-start  md:flex md:justify-between ">
        <div className="py-2 md:py-0 items-center flex justify-center md:items-start md:grid col-span-1 ">
          <img
            src={Logo}
            alt=""
            className=" w-24 h-24 md:w-20 md:h-12 object-contain "
          />
        </div>
        <div className="py-2 md:py-0 flex flex-col ">
          <div className="md:flex md:flex-col md:items-start">
            <h1 className="text-4xl font-bold mb-4 text-center md:text-start">
              Contact Us
            </h1>
            <p className="text-md flex md:justify-center items-center py-3 ">
              {" "}
              <span className="px-3 ">
                {" "}
                <CiUser className="w-6 h-6 font-bold text-white" />
              </span>{" "}
              M.Dennis
            </p>
            <p className="text-md flex md:justify-center items-center py-3">
              <span className="px-3">
                {" "}
                <GoLocation className="w-6 h-6 font-bold text-white" />
              </span>{" "}
              #14, Krishnaraj Colony, Sidha Thottam, Ganapathy, Coimbatore
            </p>
            <p className="text-md flex md:justify-center items-center py-3">
              <span className="px-3">
                {" "}
                <BiPhoneCall className="w-6 h-6 font-bold text-white" />
              </span>{" "}
              +91 9443938263
            </p>
            <p className="text-md flex md:justify-center items-center py-3">
              <span className="px-3">
                {" "}
                <AiOutlineMail className="w-6 h-6 font-bold text-white" />
              </span>{" "}
              info@dsrsystems.in
            </p>
            <div className="flex py-3  ">
              <span className="px-3  ">
                {" "}
                <FaFacebook className="w-6 h-6 text-white" />
              </span>
              <span className="px-3  ">
                <AiOutlineTwitter className="w-6 h-6 font-bold text-white" />
              </span>
              <span className="px-3   ">
                {" "}
                <TiSocialLinkedin className="w-6 h-6 font-bold text-white" />
              </span>
            </div>
          </div>
        </div>
        <div className="w-3/6 h-full">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.0615370909763!2d76.97351217470118!3d11.03400998913084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85842d414b503%3A0x896eca88e7ef82db!2sDSR%20SYSTEMS!5e0!3m2!1sen!2sin!4v1684822278543!5m2!1sen!2sin"
            className="w-80 h-48 md:w-full md:h-[20rem]"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Footer;
