import React from "react";
import Profileimg from "../assets/images/userprofile.webp";

const About = () => {
  return (
    <div className="startdiv bg-[#f3f3f3]">
      <h2 className="text-3xl font-semibold mt-5">About Us _</h2>
      <div className="grid grid-cols-3 gap-4 mt-5">
        <div class="col-span-2">
          <p className="text-[21px] leading-8 text-justify text-[#5C5C5C] font-normal">
            <span className="font-bold text-[20px] text-black">
              DSR SYSTEMS
            </span>{" "}
            was started as a small assembly unit which now has emerged as a
            system providing a complete solution for your electronics products.
            Our service starts with PCB Designing, PCB Fabrication, PCB
            Assembly, Wiring Harness, and Control Panels as per the requirements
            of our customers. We have the facility to design the Electronic
            Circuit and Fabricate the Electronic Printed Circuit Boards for the
            concepts of our distinguished Customers. And we know the importance
            of the Visual Inspection in the designed proved Electronics Boards,
            hence we are doing the Visual Inspection and providing the Test
            Certificates.
          </p>
        </div>
        <div class="col-span-1 firstrow">
          <img alt="" src={Profileimg} className="w-3/5 pl-5 profileimg" />
          <button className="bg-[#96d16f] enquirybtn mt-4">Enquiry</button>
        </div>
      </div>
      <h2 className="text-3xl font-semibold mt-5">WE ARE _</h2>
      <div className="grid grid-cols-3 gap-4 mt-5">
        <div class="col-span-2">
          <p className="text-[21px] leading-8 text-justify text-[#5C5C5C] font-normal">
            <span className="font-bold text-[20px] text-black">
              DSR SYSTEMS
            </span>{" "}
            was initiated with the ideology to outshine in every field, grow
            globally and achieve every task beyond expectations. We have
            extended our support to the customer by making a shape to their
            ideas and concepts at LOW COST, TIMELY DELIVERY, EXPECTED QUALITY
            with very CONFIDENCE AND ETHICS and FEATURE SERVICE. We commit to
            staying in-line with its Mission, Vision, and Quality Policy in
            every assignment it accomplishes.
          </p>
        </div>
        <div class="col-span-1 secoundrow">
          <img alt="" src={Profileimg} className="w-3/5 pl-5 profileimg" />
          <button className="bg-[#96d16f] enquirybtn mt-4">Enquiry</button>
        </div>
      </div>
    </div>
  );
};

export default About;
