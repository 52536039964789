import React, { useState } from "react";
import Image1 from "../assets/images/image1.jpg";
import Image2 from "../assets/images/image2.jpg";
import Image3 from "../assets/images/image3.jpg";
import Image4 from "../assets/images/image4.jpg";
import Image5 from "../assets/images/image5.jpg";
import Image6 from "../assets/images/image6.jpg";
import Image7 from "../assets/images/image7.jpg";
import Image8 from "../assets/images/image8.jpg";
import Image9 from "../assets/images/image9.jpg";
import Image10 from "../assets/images/image10.jpg";
import Image11 from "../assets/images/image11.jpg";
import Image12 from "../assets/images/image12.jpg";
import Image13 from "../assets/images/image13.jpg";
import Image14 from "../assets/images/image14.jpg";
import Image15 from "../assets/images/image15.jpg";
import Image16 from "../assets/images/image16.jpg";
import Image17 from "../assets/images/image17.jpg";
import Image18 from "../assets/images/image18.jpg";
import Image19 from "../assets/images/image19.jpg";
import Image20 from "../assets/images/image20.jpg";
import Image22 from "../assets/images/image22.jpg";
import Image23 from "../assets/images/image23.jpg";
import Image24 from "../assets/images/image24.jpg";
import Image25 from "../assets/images/image25.jpg";
import Image26 from "../assets/images/image26.jpg";
import Image27 from "../assets/images/image27.jpg";
import Modal from "react-modal";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import "../App.css";

const Gallery = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  const additembtn = () => {
    setIsOpen(true);
  };
  return (
    <div className="bg-gray-200 px-16 py-4 ">
      <h1 className="text-center font-bold text-lg md:text-6xl ">
        Our Service
      </h1>
      <div className="w-full h-full flex flex-col items-center py-6 px-5">
        <div className="md:w-full md:h-1/6 md:flex md:justify-center md:items-center  md:py-2  md:block   ">
          <img
            src={Image26}
            alt=""
            className="w-full h-32 md:w-5/6 md:h-64 rounded-xl object-cover rotate-180 md:rotate-180 "
            onClick={additembtn}
          />
        </div>

        <div className="md:w-full md:h-1/6 md:flex md:justify-center md:items-center py-2 hidden md:block  ">
          <img
            src={Image8}
            alt=""
            className="w-5/6 h-72 rounded-xl object-cover  "
            onClick={additembtn}
          />
        </div>
        <div className="md:w-full md:h-1/6 md:flex md:justify-center md:items-center  py-2  md:block   ">
          <img
            src={Image4}
            alt=""
            className="w-full h-32 md:w-5/6 md:h-64 rounded-xl object-cover rotate-180"
            onClick={additembtn}
          />
        </div>
        <div className="w-full md:w-full md:h-1/6 md:flex md:justify-center md:items-center  rotate-180 md:rotate-180 md:py-10  md:block  ">
          <img
            src={Image6}
            className=" w-full h-32 md:w-5/6 md:h-64 rounded-xl object-cover rotate-180"
            onClick={additembtn}
            alt=""
          />
        </div>
        <div className="md:w-5/6 md:h-1/6 md:flex md:justify-evenly md:items-center py-2 hidden md:block  ">
          <img
            src={Image3}
            className="h-64 w-64 object-scale-down"
            onClick={additembtn}
            alt=""
          />

          <img
            src={Image3}
            className="h-64 w-full object-scale-down "
            onClick={additembtn}
            alt=""
          />

          <img
            src={Image7}
            className="h-56 w-64 object-scale-down"
            onClick={additembtn}
            alt=""
          />
        </div>
        <div className="md:w-full md:h-1/6 md:flex md:justify-center md:items-center py-2 hidden md:block  ">
          <img
            src={Image25}
            alt=""
            className="w-5/6 h-64 rounded-xl object-cover  "
            onClick={additembtn}
          />
        </div>
        <div className="md:w-full md:h-1/6 md:flex md:justify-center md:items-center  py-2  md:block  ">
          <img
            src={Image3}
            alt=""
            className="w-full h-28 md:w-5/6 md:h-64 rounded-xl object-cover rotate-180 md:rotate-270   "
            onClick={additembtn}
          />
        </div>
        <div className=" w-full md:w-full md:h-1/6 md:flex md:justify-center md:items-center md:py-2  rotate-90 md:rotate-180 hidden md:block  ">
          <img
            src={Image22}
            alt=""
            className="md:w-5/6 h-64 rounded-xl object-cover  "
            onClick={additembtn}
          />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="itemmodel"
      >
        <div className="flex justify-between px-4 py-2">
          <h2 className="font-medium text-md"> </h2>
          <button onClick={closeModal}>
            <AiOutlineCloseCircle className="h-6 w-6" />
          </button>
        </div>

        <div className="flex justify-center items-center  py-10">
          <Swiper
            pagination={
              {
                // type: "progressbar",
              }
            }
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img src={Image1} className=" w-1/2" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img src={Image2} className="w-1/2" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image3}
                  className="w-1/2 h-64 object-contain rotate-90"
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image4}
                  className="w-1/2 h-64 object-contain rotate-90"
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image5}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image6}
                  className="w-1/2 h-52 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image7}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image8}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>{" "}
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img src={Image9} className="w-1/2 h-64" alt="" />
              </div>
            </SwiperSlide>{" "}
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image10}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>{" "}
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image11}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>{" "}
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image12}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image13}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image14}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>{" "}
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image15}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>{" "}
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image16}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>{" "}
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image17}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image18}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image18}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>{" "}
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image19}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>{" "}
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image20}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image22}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image23}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image24}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image25}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image26}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center py-2">
                <img
                  src={Image27}
                  className="w-1/2 h-64 object-contain"
                  alt=""
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </Modal>
    </div>
  );
};

export default Gallery;
